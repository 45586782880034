<template>
  <div id="app" :class="theme">
    <AppHeader />
    <div class="content">
      <router-view />
    </div>
    <AppFooter />
  </div>
</template>

<script>
import { provide, ref, onMounted } from 'vue';
import language from "./lang/language";
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';

export default {
  name: "App",
  components: {
    AppHeader,
    AppFooter,
  },
  setup() {
    // Reactive variables for theme and language
    const theme = ref('dark');
    const lang = ref("es");

    // Retrieve user preferences from localStorage
    onMounted(() => {
      const savedTheme = localStorage.getItem('theme');
      const savedLang = localStorage.getItem('language');
      if (savedTheme) theme.value = savedTheme;
      if (savedLang) lang.value = savedLang;
    });

    // Save user preferences to localStorage
    const savePreferences = (newTheme, newLang) => {
      localStorage.setItem('theme', newTheme);
      localStorage.setItem('language', newLang);
      theme.value = newTheme;
      language.switchLanguage(newLang);
    };

    // Provide the $t function to all child components
    provide('$t', (key) => {
      return key.split('.').reduce((obj, i) => obj && obj[i], language.state.translations[lang.value]) || key;
    });

    // Provide the savePreferences function to all child components
    provide('savePreferences', savePreferences);

    return {
      theme,
      lang,
      savePreferences,
    };
  },
};
</script>

<style src="./App.css"></style>
