<template>
  <section id="home" class="home-section">
    <div class="content-wrapper">
      <div class="text-content">
        <h1>{{ $t("home.home") }}</h1>
        <p>{{ $t("home.info") }}</p>
        <p>{{ $t("home.info2") }}</p>
        <p>{{ $t("home.info3") }}</p>
        <button @click="$router.push('/services')" class="goto-item-button">{{ $t("home.reedirect1") }}</button>
        <p>{{ $t("home.info4") }}</p>
        <button @click="$router.push('/contact')" class="goto-item-button">{{ $t("home.reedirect2") }}</button>
      </div>
      <div class="image-content" :class="{ visible: isImageVisible }" ref="imageContainer">
        <img src="@/assets/imgs/aker-consultores-landing-image.jpg" alt="Descriptive alt text" />
      </div>
    </div>
  </section>
  <div class="image-container">
    <img src="@/assets/imgs/CARTEL-ECOVUL-2022.png" alt="LABORA Image" class="labora-image" />
  </div>
</template>

<script>
import { setMetaTags } from '@/utils/metaTags';

export default {
  name: "HomeView",
  data() {
    return {
      isImageVisible: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isImageVisible = true;
    }, 300);
    
    setMetaTags({
      title: "Aker Consultores",
      description: "Esta es la pagina principal de la web, donde se introduce un acceso a servicios y contacto.",
      keywords: [
        "aker consultores sl",
        "aker",
        "consultores",
        "gestoria",
        "contabilidad",
        "soluciones",
        "profesionales",
        "gestion",
        "negocio",
        "contacto",
        "home",
        "services",
        "contact",
        "introduction",
        "business",
        "finance",
        "accounting"
      ],
      // image: "/path-to-your-image.jpg",
    });
  },
};
</script>

<style src="@/assets/css/views/HomeView.css"></style>
