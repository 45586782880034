<template>
  <div class="contact-container">
    <img src="@/assets/imgs/aker-consultores-valencia.jpg" alt="Contact Image" class="contact-image" :class="{ visible: isImageVisible }" />

    <div class="contact-page">
      <div class="contact-info">
        <h2>{{ $t("contact.contact") }}</h2>
        <p>{{ $t("contact.info") }}</p>
        
        <!-- Contact Details -->
        <div class="contact-details">
          <div class="contact-item">
            <strong>{{ $t("contact.location_desc") }}</strong>
            <p>{{ $t("contact.location") }}</p>
          </div>
          
          <div class="contact-item">
            <strong>{{ $t("contact.email_desc") }}</strong>
            <button @click="revealObfuscatedEmail" class="reveal-button">
              {{ emailRevealed ? email : $t("contact.email_show") }}
            </button>
          </div>
          
          <div class="contact-item">
            <strong>{{ $t("contact.phone_desc") }}</strong>
            <button @click="revealObfuscatedPhone" class="reveal-button">
              {{ phoneRevealed ? phone : $t("contact.phone_show") }}
            </button>
          </div>
        </div>
      </div>

      <!-- Embedded Map -->
      <div class="contact-map">
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3113.2752558162677!2d-0.40652978452487004!3d39.40356597949471!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd604f0a24ae491b%3A0x3702b3001b8b3e2a!2sCalle%20Huelva%2C%208%2C%2046470%20Catarroja%2C%20Valencia%2C%20Spain!5e0!3m2!1sen!2sus!4v1699370492903!5m2!1sen!2sus"
          width="100%"
          height="100%"
          style="border:0;"
          allowfullscreen=""
          loading="lazy">
        </iframe>
      </div>
    </div>
  </div>
</template>


<script>
import { getObfuscatedEmail } from '@/utils/revealObfuscated';
import { getObfuscatedPhone } from '@/utils/revealObfuscated';
import { setMetaTags } from '@/utils/metaTags';

export default {
  name: 'ContactView',
  data() {
    return {
      emailRevealed: false,
      email: '',
      phoneRevealed: false,
      phone: '',
      isImageVisible: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isImageVisible = true;
    }, 300);   
    setMetaTags({
      title: "Aker Consultores",
      description: "Pagina de contacto de la web, se ofrecen facilidades para el contacto con la gestoria.",
      keywords: [
        "aker consultores sl",
        "aker",
        "consultores",
        "gestoria",
        "contabilidad",
        "soluciones",
        "profesionales",
        "gestion",
        "negocio",
        "contacto",
        "home",
        "contact",
        "business"
      ],
      // image: "/path-to-your-image.jpg",
    });
  },
  methods: {
    revealObfuscatedEmail() {
      this.email = getObfuscatedEmail();
      this.emailRevealed = true;
    },
    revealObfuscatedPhone() {
      this.phone = getObfuscatedPhone();
      this.phoneRevealed = true;
    },
  },
};
</script>

<style src="@/assets/css/views/ContactView.css"></style>
