<template>
  <div class="about-container" >
    <section id="about" class="about-section">
      <h2>{{ $t("about.about") }}</h2>
      <p>{{ $t("about.info_top") }}</p>
      <p>{{ $t("about.info_top2") }}</p>
    </section>
    <img src="@/assets/imgs/aker-consultores-about.jpg" alt="About Image" class="about-image-1" :class="{ visible: isImageVisible }" />
  </div>

  <div class="about-container" >
    <img src="@/assets/imgs/aker-consultores-about2.jpg" alt="About Image Sec" class="about-image-2" :class="{ visible: isImageVisible }" />
    <section id="about2" class="about-section">
      <h2>{{ $t("about.about2") }}</h2>
      <p>{{ $t("about.info2") }}</p>
      <p>{{ $t("about.info3") }}</p>
      <p>{{ $t("about.info4") }}</p>
      <p>{{ $t("about.info5") }}</p>
    </section>
  </div>
</template>

<script>
import { setMetaTags } from '@/utils/metaTags';

export default {
  name: 'AboutView',
  data() {
    return {
      isImageVisible: false,
    };
  },
  mounted() {   
    setTimeout(() => {
      this.isImageVisible = true;
    }, 300);
    setMetaTags({
      title: "Aker Consultores",
      description: "Pagina explicativa de la web, donde se explica la trayectoria profesional de la gestoria.",
      keywords: [
        "aker consultores sl",
        "aker",
        "consultores",
        "gestoria",
        "contabilidad",
        "soluciones",
        "profesionales",
        "gestion",
        "negocio",
        "contacto",
        "sobre nosotros",
        "conocer",
        "home",
        "services",
        "contact",
        "introduction",
        "business",
        "web development"
      ],
      // image: "/path-to-your-image.jpg",
    });
  },
};
</script>


<style src="@/assets/css/views/AboutView.css"></style>
