<template>
  <div class="image-container">
    <img src="@/assets/imgs/gva-labora.png" alt="Generalitat Valenciana Image" class="gva-image" />
    <section id="otherinfo" class="otherinfo-section">
      <!-- Section 1 -->
      <h2>{{ $t("otherinfo.otherinfo") }}</h2>
      <h3>{{ $t("otherinfo.info") }}</h3>
      <p>{{ $t("otherinfo.disclaimer") }}</p>
    </section>
    <img src="@/assets/imgs/CARTEL-ECOVUL-2022.png" alt="LABORA Image" class="labora-image-2" />
  </div>

  </template>
  
  <script>
  import { setMetaTags } from '@/utils/metaTags';
  
  export default {
    name: 'OtherInfoView',
    mounted() {   
      setMetaTags({
        title: "Aker Consultores",
        description: "Pagina que recoge todo lo relacionado con otra informacion relevante para la administracion publica y/o clientes",
        keywords: [
          "aker consultores sl",
          "aker",
          "consultores",
          "gestoria",
          "contabilidad",
          "soluciones",
          "profesionales",
          "gestion",
          "negocio",
          "home",
          "business",
          "web development",
          "otra informacion",
          "informacion",
          "disclosure"
        ],
        // image: "/path-to-your-image.jpg",
      });
    },
  };
  </script>
  
  <style src="@/assets/css/views/OtherInfoView.css"></style>
  